import {AppLoader} from "../loaders";
import {useEffect} from "react";
import {useBetterLocation} from "../../hooks/use-better-location.hook";
import {useNavigate} from "react-router-dom";

export const AuthActionPage = () => {

    const location = useBetterLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location?.query) {
            const {mode, oobCode} = location.query;
            if (mode === "resetPassword") {
                navigate(`/auth/reset?code=${oobCode}`);
            }
        }
    }, [location]);

    return (
        <AppLoader/>
    )
}
