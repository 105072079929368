import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';

import reportWebVitals from "./reportWebVitals";
import './index.scss';
import {App} from "./App";
import './i18n';
import {AppLoader} from "./components/loaders";

ReactDOM.render(
    <Suspense fallback={<AppLoader/>}>
        <HelmetProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </HelmetProvider>
    </Suspense>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
