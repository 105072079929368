import {useTranslation} from "react-i18next";
import {Page} from "../components/common";
import {Container, Typography} from "@mui/material";

export const ClientsPage = () => {
    const {t} = useTranslation(['navigation']);
    const title = t('clients');
    return <Page title={`${title} | Kocha`}>
        <Container maxWidth="xl">
            <Typography variant="h4">{title}</Typography>
        </Container>
    </Page>
}
