import firebase from "../core/firebase";
import {
    BadRequestError,
    DuplicateEmailError,
    IgnoredError,
    NotFoundError,
    WeakPasswordError,
    WrongPasswordError
} from "../core/errors";

const handleError = (err: any) => {
    const {code} = err;
    switch (code) {
        case 'auth/cancelled-popup-request':
        case 'auth/popup-closed-by-user':
            throw new IgnoredError();
        case 'auth/email-already-in-use':
            throw new DuplicateEmailError();
        case 'auth/wrong-password':
            throw new WrongPasswordError();
        case 'auth/weak-password':
            throw new WeakPasswordError();
        case 'auth/user-not-found':
            throw new NotFoundError('NOT_FOUND');
        case 'auth/invalid-action-code':
            throw new BadRequestError('INVALID_RESET_CODE');
        default:
            throw new BadRequestError()
    }
}

/**
 * Sign in with email and password
 */
export const signInWithEmail = async (email: string, password: string) => {
    try {
        return await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
        handleError(err);
    }
};

/**
 * Sign up with email and password
 */
export const signUpWithEmail = async (email: string, password: string) => {
    try {
        return await firebase.auth().createUserWithEmailAndPassword(email, password);
    } catch (err) {
        handleError(err);
    }
}

/**
 * Sign out and clear user token and data
 */
export const signOut = firebase.auth().signOut;

/**
 * Sends password reset email with reset token.
 */
export const sendPasswordResetEmail = async (email: string) => {
    try {
        return await firebase.auth().sendPasswordResetEmail(email);
    } catch (err) {
        handleError(err);
    }
}

/**
 * Confirm password reset.
 */
export const confirmPasswordReset = async (code: string, password: string) => {
    try {
        return await firebase.auth().confirmPasswordReset(code, password);
    } catch (err) {
        handleError(err);
    }
}

/**
 * Sign in with google provider.
 */
export const signInWithGoogle = async () => {
    try {
        return await firebase
            .auth()
            .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    } catch (err) {
        handleError(err);
    }
};

/**
 * Sign in with facebook provider.
 */
export const signInWithFacebook = async () => {
    return firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider());
};

/**
 * Fires when authentication initStatus has been changed.
 * @param callback Function to call when triggered.
 */
export const onAuthStateChanged = (callback: any) => {
    return firebase.auth().onAuthStateChanged(callback);
};


/**
 * Fires when user auth id token has been changed.
 * @param callback Function to call when triggered.
 */
export const onIdTokenChanged = (callback: any) => {
    return firebase.auth().onIdTokenChanged(callback);
};
