import PropTypes from "prop-types";
import {useMemo} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import {GlobalStyles} from "./global.style";
import {palette} from "./palette";
import {typography} from "./typography";
import {ComponentsOverrides} from "./overrides";

ThemeConfig.propTypes = {
    children: PropTypes.node
};

export default function ThemeConfig({children}: any) {
    const themeOptions: any = useMemo(
        () => ({
            palette,
            // shape,
            typography,
            // breakpoints,
            // shadows,
            // customShadows
        }),
        []
    );

    const theme = createTheme(themeOptions);
    theme.components = ComponentsOverrides(theme);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <GlobalStyles/>
            {children}
        </ThemeProvider>)
}
