import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller } from "react-hook-form";

export const useFormSchema = () => {
    return {
        provider: yup,
        resolver: yupResolver,
        Controller
    }
}
