function pxToRem(value: number) {
    return `${value / 16}rem`;
}

function responsiveFontSizes({sm, md, lg}: any) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm)
        },
        '@media (min-width:960px)': {
            fontSize: pxToRem(md)
        },
        '@media (min-width:1280px)': {
            fontSize: pxToRem(lg)
        }
    };
}

const FONT_PRIMARY = 'Roboto, sans-serif';

export const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 700,
    h1: {
        fontWeight: 400,
        lineHeight: 80 / 64,
        fontSize: pxToRem(32),
        ...responsiveFontSizes({sm: 52, md: 58, lg: 64})
    },
    h2: {
        fontWeight: 400,
        lineHeight: 64 / 48,
        fontSize: pxToRem(24),
        ...responsiveFontSizes({sm: 40, md: 44, lg: 48})
    },
    h3: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(20),
        ...responsiveFontSizes({sm: 26, md: 30, lg: 32})
    },
    h4: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(18),
        ...responsiveFontSizes({sm: 20, md: 24, lg: 24})
    },
    h5: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
        ...responsiveFontSizes({sm: 16, md: 16, lg: 16})
    },
    h6: {
        fontWeight: 400,
        lineHeight: 28 / 14,
        fontSize: pxToRem(14),
        ...responsiveFontSizes({sm: 14, md: 14, lg: 14})
    },
    subtitle1: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(16)
    },
    subtitle2: {
        fontWeight: 400,
        lineHeight: 22 / 14,
        fontSize: pxToRem(14)
    },
    body1: {
        lineHeight: 1.5,
        fontSize: pxToRem(14)
    },
    body2: {
        lineHeight: 22 / 14,
        fontSize: pxToRem(12)
    },
    caption: {
        lineHeight: 1.5,
        fontSize: pxToRem(12),
    },
    caption2: {
        lineHeight: 1.5,
        fontSize: pxToRem(12),

    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase'
    },
    button: {
        fontWeight: 700,
        lineHeight: 24 / 14,
        fontSize: pxToRem(14),
        textTransform: 'capitalize'
    }
};
