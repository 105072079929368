import {Box, styled} from "@mui/material";

const RootStyle = styled(Box)(({theme}: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 64,
    height: 64,
    [theme.breakpoints.up('lg')]: {
        width: 100,
        height: 100,
    }
}));


export const Logo = () => {
    return (
        <RootStyle>
            <img src="/logo.png" alt="Kocha Logo" width="40%"/>
        </RootStyle>
    );
}
