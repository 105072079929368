import {TextField} from "@mui/material";

export const KTextField = ({name, label, register, errors, ...props}: any) => {
    return (<TextField
            error={errors[name]}
            {...register(name)}
            variant="filled"
            fullWidth
            id={props.id || name}
            label={label}
            name={name}
            autoComplete={props.autoComplete || name}
            helperText={errors[name]?.message}
            {...props}
        />
    )
}
