import {useTranslation} from "react-i18next";
import {useFormSchema} from "../use-form-schema.hook";
import {useForm} from "react-hook-form";

export const useForgotPasswordValidation = () => {
    const {t} = useTranslation(['common']);

    const {provider, resolver, Controller} = useFormSchema();
    const schema = provider.object().shape({
        email: provider.string()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
    });

    const form = useForm({
        resolver: resolver(schema as any)
    });

    return {
        Controller,
        ...form
    }


}
