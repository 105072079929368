import React from 'react';
import ThemeConfig from "./theme";
import {Router} from "./routes";
import {AuthProvider} from "./hooks";

export const App = () => {
    return (
        <ThemeConfig>
            <AuthProvider>
                <Router/>
            </AuthProvider>
        </ThemeConfig>
    )
}
