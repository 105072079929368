import {useLocation} from "react-router-dom";
import * as qs from 'qs';
import {useEffect, useState} from "react";

export const useBetterLocation = () => {
    const myLocation = useLocation();
    const [location, setLocation] = useState<any>(null);

    const getQuery = (input: string) => {
        if (input.indexOf('?') === 0) {
            input = input.substring(1, input.length);
        }
        return qs.parse(input);
    }

    useEffect(() => {
        if (myLocation) {
            const {hash, key, pathname, search, state} = myLocation;
            setLocation({
                hash,
                key,
                pathname,
                search,
                state,
                query: getQuery(search),
            });
        }
    }, [myLocation]);

    return location;
}
