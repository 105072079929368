import {Avatar, AvatarGroup, Box, Card, Grid, Typography} from "@mui/material";
import Moment from "react-moment";
import {IconMessage2} from "@tabler/icons";

const KanbanBoardColumn = ({column, children, ...other}: any) => {
    return <Box
        sx={{
            width: 300,
            minHeight: 50,
            padding: '0 16px',
            borderRight: 'solid 1px #E6E6E6'
        }}>
        <Box sx={{borderLeft: 'solid 3px grey', paddingLeft: '16px'}}>
            <Typography variant="caption" sx={{textTransform: 'uppercase'}}>{column.title}</Typography>
        </Box>
        {children}
    </Box>
}

const KanbanBoardCard = ({card, children, ...other}: any) => {
    return <Card sx={{margin: '16px 0', padding: '8px'}}>
        <Typography variant="subtitle2">{card.title}</Typography>
        <Typography variant="caption">
            <Moment format="MMM D, yy">{card.date}</Moment>
        </Typography>
        <Box sx={{marginTop: '16px'}}>
            <Grid container>
                <Grid item  xs="auto">
                    <AvatarGroup>
                        <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src="https://media-exp1.licdn.com/dms/image/C4E03AQFerK4vnQ5ChA/profile-displayphoto-shrink_100_100/0/1577462423968?e=1641427200&v=beta&t=ZVmoGBfrLJ5YYfhbBeJO_3NJWW6NO4U7GMT6ul5Kb44"/>
                        <Avatar sx={{ width: 24, height: 24 }} alt="Travis Howard" src="/static/images/avatar/2.jpg"/>
                        <Avatar sx={{ width: 24, height: 24 }} alt="Cindy Baker" src="/static/images/avatar/3.jpg"/>
                        <Avatar sx={{ width: 24, height: 24 }} alt="Agnes Walker" src="/static/images/avatar/4.jpg"/>
                        <Avatar sx={{ width: 24, height: 24 }} alt="Trevor Henderson" src="/static/images/avatar/5.jpg"/>
                    </AvatarGroup>
                </Grid>
                <Grid item xs sx={{textAlign: 'right'}}>
                    <IconMessage2 stroke={1} color="grey"/>
                </Grid>
            </Grid>
        </Box>
    </Card>
}

export const KanbanBoard = ({board = {}}: any) => {
    const {columns = []} = board

    return <Box sx={{display: 'flex', justifyContent: 'stretch', overflowX: 'auto', padding: '0 16px'}}>
        {
            columns.map((column: any) => {

                const {cards = []} = column;
                return <KanbanBoardColumn column={column} key={column.id}>
                    {
                        cards.map((card: any) => {
                            return <KanbanBoardCard card={card} key={card.id}/>
                        })
                    }
                </KanbanBoardColumn>
            })
        }
    </Box>
}
