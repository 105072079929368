import {useTranslation} from "react-i18next";
import {Page, PageHeader} from "../components/common";
import {Button, Container, Grid, Typography} from "@mui/material";
import {IconSearch} from "@tabler/icons";
import {ITaskBoardStore, useTaskStore} from "../stores/tasks.store";
import {useEffect} from "react";
import {KanbanBoard} from "../components/board/KanbanBoard";

export const TasksPage = () => {
    const {t} = useTranslation(['navigation']);
    const title = t('tasks');
    const {loadBoard, board} = useTaskStore((state: ITaskBoardStore) => state);

    useEffect(() => {
        loadBoard("");
    }, []);

    return <Page title={`${title} | Kocha`}>
        <Grid container direction="column">
            <Grid item xs={1}>
                <PageHeader>
                    <Grid container>
                        <Grid item xs="auto">
                            <Typography variant="h4">{title}</Typography>
                        </Grid>
                        <Grid item xs sx={{textAlign: 'right'}}>
                            <Button startIcon={<IconSearch stroke={1}/>}>Search</Button>
                        </Grid>
                    </Grid>
                </PageHeader>
            </Grid>
            <Grid item xs>
                {board
                    ? <KanbanBoard board={board}/>
                    : <Container>Loading ...</Container>}
            </Grid>
        </Grid>
    </Page>
}
