import PropTypes from "prop-types";
import {Alert, Snackbar} from "@mui/material";

export const SuccessBar = ({message, onClose}: any) => {
    return <Snackbar open={message}
                     autoHideDuration={3000}
                     onClose={onClose}
                     anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}>
        <Alert severity="success" variant="filled">
            {message}
        </Alert>
    </Snackbar>
}

SuccessBar.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func
};
