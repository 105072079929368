import create from 'zustand';
import {IBoard} from "../models/tasks/board";

const boardData = {
    columns: [
        {
            id: 1,
            title: 'Backlog',
            cards: [
                {
                    id: 1,
                    title: 'Add card',
                    description: 'Add capability to add a card in a column',
                    date: new Date('Thu Nov 05 2021 19:23:18 GMT+0100')
                },
                {
                    id: 2,
                    title: 'Nice',
                    description: 'Add capability to add a card in a column',
                    date: new Date()
                },
            ]
        },
        {
            id: 3,
            title: 'Doing',
            cards: [
                {
                    id: 4,
                    title: 'Drag-n-drop support',
                    description: 'Move a card between the columns'
                },
            ]
        },
        {
            id: 9,
            title: 'In Progress',
            cards: [
            ]
        },
        {
            id: 19,
            title: 'In Progress',
            cards: [
            ]
        },
        {
            id: 29,
            title: 'In Progress',
            cards: [
            ]
        },
        {
            id: 39,
            title: 'In Progress',
            cards: [
            ]
        },
        {
            id: 49,
            title: 'In Progress',
            cards: [
            ]
        }
    ]
}

export interface ITaskBoardStore {
    board: IBoard | null,
    loadBoard: { (userId: string): void; };
}

const creator = (set: any): ITaskBoardStore => {

    const board: IBoard | null = null;

    const loadBoard = (userId: string) => {
        set(() => ({board: boardData}));
    };

    return ({
        board,
        loadBoard
    });
}

export const useTaskStore = create(creator);
