export class BaseError {
    constructor(public code: number, public key: string, public message: string) {
    }
}

export class NotFoundError extends BaseError {
    constructor(key?: string, message?: string) {
        super(404, key || 'NOT_FOUND', message || 'Not found');
    }
}

export class DuplicateEmailError extends BaseError {
    constructor() {
        super(400, 'DUPLICATE_EMAIL', 'Email already exists');
    }
}

export class WrongPasswordError extends BaseError {
    constructor() {
        super(400, 'WRONG_PASSWORD', 'Wrong password');
    }
}

export class WeakPasswordError extends BaseError {
    constructor() {
        super(400, 'WEAK_PASSWORD', 'Weak password');
    }
}

export class IgnoredError extends BaseError {
    constructor() {
        super(400, 'IGNORE', 'Ignored error');
    }
}

export class BadRequestError extends BaseError {
    constructor(key?: string, message?: string) {
        super(400, key || 'BAD_REQUEST', message || 'Bad request');
    }
}
