import PropTypes from "prop-types";
import {Alert, Snackbar} from "@mui/material";

export const ErrorBar = ({error, onClose, autoHideDuration = 5000, ...props}: any) => {
    return <Snackbar open={error}
                     autoHideDuration={autoHideDuration}
                     onClose={onClose}
                     anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                     {...props}>
        <Alert severity="error" variant="filled">
            {error?.message}
        </Alert>
    </Snackbar>
}

ErrorBar.propTypes = {
    error: PropTypes.any,
    onClose: PropTypes.func
};
