export const Card = (theme: any) => {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    border: '2px',
                    boxShadow: "0 2px 2px -2px rgba(0,0,0,0.3)"
                }
            },
        }
    };
}
