import firebase from "firebase/app";
import {NotFoundError} from "../core/errors";
import {buildQuery} from "../core/query";

const firestore = firebase.firestore();

/**
 * Gets collection reference based on the collection name
 * @param name Unique collection name
 */
export const getCollectionReference = (name: string) => {
    return firestore.collection(name);
}

/**
 * Finds document by primary key and returns complete data.
 * @param reference
 * @param key
 */
export const findByPk = async (reference: any, key: string) => {
    const doc = await reference.doc(key).get();
    const data = doc.data();

    if (!data) {
        throw new NotFoundError();
    }
    return {id: doc.id, ...data};
}

/**
 * Finds documents by params.
 * @param reference
 * @param params
 */
export const find = async (reference: any, params: any) => {
    const ref = buildQuery(reference, params);
    const snapshot = await ref.get();
    const result: Array<any> = [];
    snapshot.forEach((doc: any) => {
        // @ts-ignore
        result.push({id: doc.id, ...doc.data()});
    });

    return result;
}

/**
 * Finds single document by params.
 * @param reference
 * @param params
 */
export const findOne = async (reference: any, params: any) => {
    const result = await find(reference, params);
    return result && result.length ? result[0] : null;
}

/**
 * Creates document and returns complete data
 * @param reference
 * @param payload
 */
export const create = async (reference: any, payload: any) => {
    const ref = await reference.add(payload);
    const data = (await ref.get()).data();
    return {id: ref.id, ...data};
}

/**
 * Updates document and returns complete data
 * @param reference
 * @param key
 * @param payload
 */
export const update = async (reference: any, key: string, payload: any) => {
    const doc = reference.doc(key)
    try {
        await doc.update(payload, {merge: true});
        const data = (await doc.get()).data();
        return {id: doc.id, ...data};
    } catch (err) {
        throw new NotFoundError();
    }
}
