import {merge} from 'lodash';
import {Button} from "./Button";
import {Typography} from "./Typography";
import {Card} from "./Card";

export const ComponentsOverrides = (theme: any): any => {
    return merge(
        Button(theme),
        Typography(theme),
        Card(theme),
    );
}
