import {Avatar, Box, Button, Checkbox, FormControlLabel, Grid, Link, Typography} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useAuth} from "../../hooks";
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {ErrorBar} from "../common";
import {useSignUpValidation} from "../../hooks/auth";
import {KTextField} from "../form";

const useStyles = makeStyles((theme: any) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const SignUp = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {authError, clearAuthError, signInWithGoogle, signUpWithEmail} = useAuth();
    const {Controller, control, register, handleSubmit, formState: {errors}} = useSignUpValidation();

    const onSubmit = async (data: any) => {
        const user = await signUpWithEmail(data);
        if (user) {
            navigate("/");
        }
    }

    return (<>
            <ErrorBar error={authError} onClose={() => clearAuthError()}/>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign up
            </Typography>

            <Box mt={4} mb={4}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => signInWithGoogle()}>
                    Continue with Google
                </Button>
            </Box>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <KTextField
                            errors={errors}
                            register={register}
                            name="firstName"
                            label="First Name"
                            autoFocus
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <KTextField
                            errors={errors}
                            register={register}
                            label="Last Name"
                            name="lastName"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <KTextField
                            errors={errors}
                            register={register}
                            label="Email Address"
                            name="email"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <KTextField
                            errors={errors}
                            register={register}
                            required
                            name="password"
                            label="Password"
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            {...register("toc")}
                            control={control}
                            defaultValue={false}
                            render={({field}: any) => {
                                return <>
                                    <FormControlLabel
                                        {...field}
                                        control={<Checkbox
                                            value="toc" color="primary"/>}
                                        label={<Typography variant="body2">I agree with kocha <Link href="/info/terms">Terms
                                            & Conditions</Link>.</Typography>}
                                    />
                                    {errors.toc?.message &&
                                    <Typography variant="body2" color="error">{errors.toc?.message}</Typography>}
                                </>
                            }}
                        />

                    </Grid>
                </Grid>
                <Grid container mt={3} mb={3}>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="/auth/signin" variant="body2">
                            Already have an account? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
