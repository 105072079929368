import React, {useState} from "react";
import {Outlet, useNavigate} from 'react-router-dom';
import {styled} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {useAuth} from "../hooks";
import {Navbar, Sidebar} from "../components/common";
import {MHidden} from "../components/@material-extend";
import {AppLoader} from "../components/loaders";

const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 64;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({theme}) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.grey.A100,
    [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    }
}));

export const MainLayout = () => {
    const {user, isLoading} = useAuth();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    if (isLoading) {
        return <AppLoader/>
    }
    if (!user) {
        navigate("/auth/signin");
    }

    return (
        <RootStyle>
            <CssBaseline/>
            <MHidden width="lgUp">
                <Navbar onOpenSidebar={() => setOpen(true)}/>
            </MHidden>
            <Sidebar isOpenSidebar={open}
                     onNavigate={(item: any) => {
                         setOpen(false);
                         navigate(item.path)
                     }}
                     onCloseSidebar={() => setOpen(false)}/>
            <MainStyle>
                <Outlet/>
            </MainStyle>
        </RootStyle>
    )
}
