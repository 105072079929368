export const AppLoader = () => {
    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <span>Loading ...</span>
    </div>
}
