import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment'

const localizer = momentLocalizer(moment)

export const Scheduler = ({events = [], onSelectEvent}: any) => {

    events = events || [];
    events.push({
        title: "Cool event",
        start: new Date(),
        end: new Date(),
        allDay: false
    });
    return <div>
        <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={onSelectEvent}
            style={{height: 500}}
        />
    </div>
}
