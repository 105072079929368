import {Page, PageHeader} from "../components/common";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {CalendarEvent} from "../components/scheduling";
import {useTranslation} from "react-i18next";

export const DashboardPage = () => {
    const {t} = useTranslation(['navigation']);
    const title = t('dashboard');
    return (
        <Page data-key="Page" title={`${title} | Kocha`}>
            <PageHeader>
                <Typography variant="h5">Welcome, Andrej</Typography>
            </PageHeader>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box sx={{margin: '0 16px'}}>
                        <Stack spacing={1}>
                            <CalendarEvent></CalendarEvent>
                            <CalendarEvent></CalendarEvent>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Page>
    )
}
