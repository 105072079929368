export const Button = (theme: any) => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: '400'
                }
            }
        }
    };
}
