import {
    Avatar,
    AvatarGroup,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Stack,
    styled,
    Typography,
    useTheme
} from "@mui/material";
import {alpha} from '@mui/material/styles';
import {IconCalendarEvent} from "@tabler/icons";

const RootStyle = styled((props) => <Card {...props} />)(({theme}: any) => ({}));

const DateBoxStyle = styled(Box)(({theme}: any) => ({
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    backgroundColor: alpha(theme.palette.secondary.lighter, .1),
    color: alpha(theme.palette.secondary.lighter, .8),
    borderRadius: theme.shape.borderRadius
}))

const AvatarStyle = ({children, ...props}: any) => (
    <Avatar sx={{height: '32px', width: '32px'}} {...props}>
        {children}
    </Avatar>
);

export const CalendarEvent = () => {

    const theme = useTheme();

    return <RootStyle>
        <CardContent>
            <Grid container alignItems="center">
                <Grid item xs="auto">
                    <DateBoxStyle>
                        <Typography variant="h4" align="center">23</Typography>
                        <Typography align="center">APR</Typography>
                    </DateBoxStyle>
                </Grid>
                <Grid item xs>
                    <Typography variant="h5" gutterBottom={true}>Online session with Andrej</Typography>
                    <Stack direction="row" spacing={1}>
                        <IconCalendarEvent size={16} color={theme.palette.secondary.main}/>
                        <Typography variant="body2" color={theme.palette.grey[800]}>
                            Wednesday
                        </Typography>
                        <Typography variant="body2" color={theme.palette.grey[800]}>
                            &#183;
                        </Typography>
                        <Typography variant="body2" color={theme.palette.grey[800]}>
                            11:45
                        </Typography>
                        <Typography variant="body2" color={theme.palette.grey[800]}>
                            &#183;
                        </Typography>
                        <Typography variant="body2" color={theme.palette.grey[800]}>
                            45 minutes
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs="auto">
                    <AvatarGroup max={4}>
                        <AvatarStyle></AvatarStyle>
                        <AvatarStyle></AvatarStyle>
                        <AvatarStyle></AvatarStyle>
                        <AvatarStyle></AvatarStyle>
                        <AvatarStyle></AvatarStyle>
                    </AvatarGroup>
                </Grid>
            </Grid>
        </CardContent>
    </RootStyle>
}

CalendarEvent.propTypes = {};
