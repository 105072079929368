import {Avatar, Button, Grid, Link, Typography} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useAuth} from "../../hooks";
import {makeStyles} from "@mui/styles";
import {ErrorBar, SuccessBar} from "../common";
import {useTranslation} from "react-i18next";
import {useResetValidation} from "../../hooks/auth/use-reset-validation.hook";
import {KTextField} from "../form";
import {useBetterLocation} from "../../hooks/use-better-location.hook";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const ResetPasswordChange = () => {
    const {t} = useTranslation(['auth']);
    const [message, setMessage] = useState('');
    const {authError, clearAuthError, confirmPasswordReset} = useAuth();
    const [code, setCode] = useState<any>(null);
    const classes = useStyles();
    const {register, handleSubmit, formState: {errors}} = useResetValidation();

    const location = useBetterLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location?.query) {
            console.log('LOCATION', location)
            const {code} = location.query;
            setCode(code);
        }
    }, [location]);

    const onSubmit = async (data: any) => {
        const {password} = data;
        const reset = await confirmPasswordReset(code, password);
        if (reset) {
            setMessage('Password changed. Use your new password to sign in.')
            navigate("/auth/signin");
        }
    }

    return (
        <>
            <ErrorBar error={authError} onClose={() => clearAuthError()}/>
            <SuccessBar message={message} onClose={() => setMessage('')}/>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Reset password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <KTextField
                            errors={errors}
                            register={register}
                            required
                            name="password"
                            label={t('PASSWORD')}
                            type="password"
                        />
                    </Grid>
                </Grid>
                <Grid container mt={3} mb={3}>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            {t('RESET')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="/auth/forgot" variant="body2">
                            Send new reset code
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
