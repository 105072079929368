import {useNavigate} from "react-router-dom";
import {Avatar, Box, Button, Grid, Link, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useAuth} from "../../hooks";
import {makeStyles} from "@mui/styles";
import {ErrorBar} from "../common";
import {useTranslation} from "react-i18next";
import {useSignInValidation} from "../../hooks/auth";

const useStyles = makeStyles((theme: any) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const SignIn = () => {
    const {t} = useTranslation(['auth']);
    const {authError, clearAuthError, signInWithGoogle, signInWithEmail} = useAuth();
    const navigate = useNavigate();
    const classes = useStyles();
    const {register, handleSubmit, formState: {errors}} = useSignInValidation();

    const onSubmit = async (data: any) => {
        const {email, password} = data;
        const user = await signInWithEmail(email, password);
        if (user) {
            navigate("/");
        }
    }

    const onSignInWithGoogle = async () => {
        const user = await signInWithGoogle();
        if (user) {
            navigate("/");
        }
    }

    return (
        <>
            <ErrorBar error={authError} onClose={() => clearAuthError()}/>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box mt={4} mb={4}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onSignInWithGoogle()}>
                    {t('CONTINUE_WITH_GOOGLE')}
                </Button>
            </Box>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            error={errors['email']}
                            {...register("email", {required: true,})}
                            variant="filled"
                            required
                            fullWidth
                            id="email"
                            label={t('EMAIL')}
                            name="email"
                            autoComplete="email"
                            helperText={errors?.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={errors['password']}
                            {...register("password", {required: true,})}
                            variant="filled"
                            required
                            fullWidth
                            name="password"
                            label={t('PASSWORD')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={errors?.password?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Link href="/auth/forgot" variant="body2">
                            Forgot your password?
                        </Link>
                    </Grid>
                </Grid>
                <Grid container mt={3} mb={3}>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            {t('SIGN_IN')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="/auth/signup" variant="body2">
                            Don't have an account? Sign up
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
