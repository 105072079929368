import {Helmet} from "react-helmet-async";
import {forwardRef} from "react";
import {Box} from "@mui/material";
import PropTypes from "prop-types";

export const Page = forwardRef(({children, sx, title = '', ...other}: any, ref) => (
    <Box ref={ref} {...other} sx={{
        minHeight: '100%',
        ...sx
    }}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </Box>
));

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

