import React, {createContext, useContext, useEffect, useState} from "react";
import * as auth from '../services/auth.service';
import {IUserStore, useUserStore} from "../stores/user.store";
import * as userService from "../services/user.service";
import {IUser} from "../models/user/user";
import {useTranslation} from "react-i18next";
import {IgnoredError} from "../core/errors";

export interface IAuth {
    user: any,
    authError: any,
    clearAuthError: any,
    isLoading: boolean,
    signInWithEmail: { (email: string, password: string): Promise<IUser>; };
    signUpWithEmail: { (data: any): Promise<IUser>; };
    signOut: { (): void; };
    sendPasswordResetEmail: { (email: string): Promise<boolean>; };
    confirmPasswordReset: { (code: string, password: string): Promise<boolean>; };
    signInWithGoogle: { (): Promise<IUser>; };
}

const AuthContext = createContext({});

export const AuthProvider = ({children}: any) => {
    const value = useProvideAuth();
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = (): IAuth => {
    return useContext(AuthContext) as IAuth;
};

const useProvideAuth = () => {
    const {user, setUser} = useUserStore((state: IUserStore) => state);
    const [isLoading, setLoading] = useState(true);
    const [authError, setAuthError] = useState(null);
    const {t} = useTranslation(['auth']);

    const clearAuthError = () => {
        setAuthError(null);
    }

    const setLocalizedAuthError = (err: any) => {
        if (err && !(err instanceof IgnoredError)) {
            err.message = t(err.key);
            setAuthError(err);
        }
    }

    const signInWithEmail = async (email: string, password: string) => {
        try {
            const {user}: any = await auth
                .signInWithEmail(email, password);
            return user;

        } catch (err) {
            setLocalizedAuthError(err);
        }

    };

    const signUpWithEmail = async (data: any) => {
        const {email, password} = data;
        try {
            let {user}: any = await auth
                .signUpWithEmail(email, password);

            user = await handleUserCreate(user, data);
            setUser(user);
            return user;
        } catch (err) {
            setLocalizedAuthError(err);
        }
    };

    const signInWithGoogle = async () => {
        try {
            let {user}: any = await auth
                .signInWithGoogle();

            user = await handleUserCreate(user);
            setUser(user);
            return user;
        } catch (err) {
            setLocalizedAuthError(err);
        }
    };

    const signInWithFacebook = async () => {
        let {user}: any = await auth
            .signInWithFacebook();

        user = await handleUserCreate(user);

        setUser(user);

        return user;
    };

    const signOut = async () => {
        await auth
            .signOut();

        setUser(null);
    };

    const sendPasswordResetEmail = async (email: string) => {
        try {
            await auth
                .sendPasswordResetEmail(email);

            return true;
        } catch (err) {
            setLocalizedAuthError(err);
        }
    };

    const confirmPasswordReset = async (code: string, password: string) => {

        try {
            await auth
                .confirmPasswordReset(code, password);

            return true;
        } catch (err) {
            setLocalizedAuthError(err);
        }
    };

    const handleUserCreate = async (user: any, data?: any) => {
        const {uid, email} = user;
        return userService.createOrUpdateUser(Object.assign({uid, email}, data || {}));
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }

            setLoading(false);

        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onIdTokenChanged((user: any) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    return {
        user,
        authError,
        clearAuthError,
        isLoading,
        signInWithEmail,
        signUpWithEmail,
        signInWithGoogle,
        signInWithFacebook,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
    };
}
