import create from 'zustand';
import {IUser} from "../models/user/user";

export interface IUserStore {
    user: any;
    setUser: { (user: IUser | null): void; };
}

const creator = (set: any): IUserStore => {
    /**
     * User info
     */
    const user: IUser | null = null;

    /**
     * Sets user info
     * @param user
     */
    const setUser = (user: IUser | null) => {
        set(() => ({user}))
    };

    return ({
        user,
        setUser
    });
}

export const useUserStore = create(creator);
