import {Avatar, Button, Grid, Link, Typography} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useAuth} from "../../hooks";
import {makeStyles} from "@mui/styles";
import {ErrorBar, SuccessBar} from "../common";
import {useTranslation} from "react-i18next";
import {KTextField} from "../form";
import {useForgotPasswordValidation} from "../../hooks/auth/use-forgot-password-validation.hook";
import {useState} from "react";

const useStyles = makeStyles((theme: any) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const ForgotPasswordPage = () => {
    const {t} = useTranslation(['auth']);
    const [message, setMessage] = useState('');
    const {authError, clearAuthError, sendPasswordResetEmail} = useAuth();
    const classes = useStyles();
    const {register, handleSubmit, formState: {errors}} = useForgotPasswordValidation();

    const onSubmit = async (data: any) => {
        const {email} = data;
        const reset = await sendPasswordResetEmail(email);
        if (reset) {
            setMessage('Check your email for instructions')
        }
    }

    return (
        <>
            <ErrorBar error={authError} onClose={() => clearAuthError()}/>
            <SuccessBar message={message} onClose={() => setMessage('')}/>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Forgot password?
            </Typography>
            <Typography component="h6" variant="body2">
                Enter your email and we will send you password reset instructions.
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <KTextField
                            errors={errors}
                            register={register}
                            required
                            label={t('EMAIL')}
                            name="email"
                        />
                    </Grid>
                </Grid>
                <Grid container mt={3} mb={3}>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            {t('SIGN_IN')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="/auth/signin" variant="body2">
                            Back to Sign In
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
