import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {Drawer, List, ListItem, ListItemIcon, ListItemText, styled, Tooltip} from "@mui/material";
import {
    IconCalendarEvent,
    IconDashboard,
    IconFileInvoice,
    IconLayoutKanban,
    IconSettings,
    IconUsers
} from "@tabler/icons";
import {useTranslation} from "react-i18next";
import {MHidden} from '../../components/@material-extend';
import {Logo} from "./logo";

const getNavItems = ({t}: any) => {

    const ICON_STROKE = 2;
    return [
        {
            title: t('dash'),
            path: '/dashboard',
            icon: <IconDashboard stroke={ICON_STROKE}/>
        },
        {
            title: t('schedule'),
            path: '/schedule',
            icon: <IconCalendarEvent stroke={ICON_STROKE}/>
        },
        {
            title: t('clients'),
            path: '/clients',
            icon: <IconUsers stroke={ICON_STROKE}/>
        },
        {
            title: t('tasks'),
            path: '/tasks',
            icon: <IconLayoutKanban stroke={ICON_STROKE}/>
        },
        {
            title: t('invoices'),
            path: '/invoices',
            icon: <IconFileInvoice stroke={ICON_STROKE}/>
        },
        {
            title: t('settings'),
            path: '/settings',
            icon: <IconSettings stroke={ICON_STROKE}/>
        },
    ];
}

const DRAWER_WIDTH = 100;
// const DRAWER_WIDTH_LG = '90%';

const RootStyle = styled('div')(({theme}: any) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const ListItemStyle = styled(ListItem)(({theme}: any) => ({
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px 0'
    }
}));

const ListItemIconStyle = styled((props) => <ListItemIcon {...props} />)(({theme, active}: any) => ({
    [theme.breakpoints.up('lg')]: {
        justifyContent: 'center',
        color: active ? theme.palette.secondary.main : theme.palette.grey.A500
    }
}));

const ListItemTextStyle = styled(ListItemText)(({theme}: any) => ({
    [theme.breakpoints.up('lg')]: {
        '> span': {fontSize: '.7rem'},
        color: theme.palette.grey[600]
    }
}));

const NavItem = ({item, active, onNavigate}: any) => {
    const isActiveRoot = active(item.path);
    const {title, icon} = item;
    const activeRootStyle = {};

    return (
        <Tooltip title={title} placement="right">
            <ListItemStyle onClick={() => onNavigate(item)}
                           sx={{
                               ...(isActiveRoot && activeRootStyle)
                           }}
                           button>
                <ListItemIconStyle stroke={1} active={isActiveRoot}>
                    {icon}
                </ListItemIconStyle>
                <MHidden width="lgUp">
                    <ListItemTextStyle>{title}</ListItemTextStyle>
                </MHidden>
            </ListItemStyle>
        </Tooltip>
    )
}

NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
    onNavigate: PropTypes.func,
};

export const Sidebar = ({isOpenSidebar, onCloseSidebar, onNavigate}: any) => {
    const {pathname} = useLocation();
    const match = (path: string) => (path ? !!matchPath({path, end: false}, pathname) : false);
    const {t} = useTranslation(['navigation']);
    const items = getNavItems({t});

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }

    }, [pathname]);

    const handleNavItemSelection = (item: any) => {
        onNavigate && onNavigate(item);
    }

    const renderContent = () => {
        return (

            <List component="nav" aria-label="main links">
                {
                    items.map((item: any) => {
                        return <NavItem key={item.path}
                                        item={item}
                                        active={match}
                                        onNavigate={handleNavItemSelection}/>
                    })
                }
            </List>
        )
    }

    return <RootStyle>
        <MHidden width="lgUp">
            <Drawer
                open={isOpenSidebar}
                onClose={onCloseSidebar}
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.default',
                        borderRight: 1,
                        borderColor: 'palette.grey.A600',
                    }
                }
                }>

                {renderContent()}
            </Drawer>
        </MHidden>

        <MHidden width="lgDown">
            <Drawer
                open
                variant="persistent"
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.default',
                        border: 0,
                        borderRight: 1,
                        borderRightColor: '#DDE0E5',
                        width: DRAWER_WIDTH
                    }
                }}>
                <Logo/>
                {renderContent()}
            </Drawer>
        </MHidden>
    </RootStyle>

}

Sidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
    onNavigate: PropTypes.func,
};
