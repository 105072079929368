import React from "react";
import {Outlet} from 'react-router-dom';
import {Box, Container, CssBaseline, Link, Typography} from "@mui/material";
import {styled} from "@mui/styles";

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://kocha.me/">
                Kocha
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const RootStyle = styled('div')(({theme}: any) => ({
    [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

export const AuthLayout = () => {
    return (
        <RootStyle>
            <CssBaseline/>
            <Container maxWidth="sm">
                <Outlet/>
            </Container>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </RootStyle>
    )
}
