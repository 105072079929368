import {Navigate, useRoutes} from 'react-router-dom';
import {DashboardPage} from "./pages/dashboard.page";
import {AuthLayout} from "./layouts/auth.layout";
import {SignIn} from "./components/auth/signin";
import {MainLayout} from "./layouts/main.layout";
import {SignUp} from "./components/auth/signup";
import {ClientsPage} from "./pages/clients.page";
import {TasksPage} from "./pages/tasks.page";
import {ChatPage} from "./pages/chat.page";
import {SettingsPage} from "./pages/settings.page";
import {InvoicesPage} from "./pages/invoices.page";
import {SchedulePage} from "./pages/schedule.page";
import {ForgotPasswordPage} from "./components/auth/forgot";
import {AuthActionPage} from "./components/auth/action";
import {ResetPasswordChange} from "./components/auth/reset";

export const Router = () => {

    return useRoutes([
        {
            path: '/',
            element: <MainLayout/>,
            children: [
                {path: '/', element: <Navigate to="/dashboard" replace/>},
                {path: '/dashboard', element: <DashboardPage/>},
                {path: '/schedule', element: <SchedulePage/>},
                {path: '/clients', element: <ClientsPage/>},
                {path: '/tasks', element: <TasksPage/>},
                {path: '/invoices', element: <InvoicesPage/>},
                {path: '/settings', element: <SettingsPage/>},
            ]
        },
        {
            path: '/auth',
            element: <AuthLayout/>,
            children: [
                {path: '/', element: <Navigate to="/signin" replace/>},
                {path: 'signin', element: <SignIn/>},
                {path: 'signup', element: <SignUp/>},
                {path: 'forgot', element: <ForgotPasswordPage/>},
                {path: 'reset', element: <ResetPasswordChange/>},
                {path: 'action', element: <AuthActionPage/>},
            ]
        }
    ]);
}
