import {styled} from "@mui/styles";
import {Toolbar} from "@mui/material";

const PageHeaderStyle = styled((props) => <Toolbar {...props} />)(({theme}: any) => ({
    // backgroundColor: theme.palette.background.default
}));

export const PageHeader = ({children}: any) => {
    return <PageHeaderStyle>{children}</PageHeaderStyle>
}
