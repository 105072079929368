import PropTypes from 'prop-types';
import {AppBar, Box, IconButton, Popover, Stack, styled, Toolbar, Typography} from "@mui/material";
import {MHidden} from '../@material-extend';
import {IconMenu2, IconUser} from "@tabler/icons";
import {Logo} from "./logo";
import {useState} from "react";


const DRAWER_WIDTH = 80;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({theme}: any) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
        backgroundColor: 'transparent'
    }
}));

const ToolbarStyle = styled(Toolbar)(({theme}: any) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

export const Navbar = ({onOpenSidebar}: any) => {
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const handleClick = (event: any) => {
        setAccountAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAccountAnchorEl(null);
    };

    const open = Boolean(accountAnchorEl);

    return (
        <RootStyle>
            <ToolbarStyle>
                <MHidden width="lgUp">
                    <IconButton color="primary" onClick={onOpenSidebar}>
                        <IconMenu2/>
                    </IconButton>
                </MHidden>

                <Box sx={{flexGrow: 1}}/>
                <MHidden width="lgUp">
                    <Logo/>
                </MHidden>
                <Box sx={{flexGrow: 1}}/>

                <Stack direction="row" spacing={{xs: 0.5, sm: 1.5}}>
                    <IconButton color="primary" onClick={handleClick}>
                        <IconUser/>
                    </IconButton>
                    <Popover
                        open={open}
                        anchorEl={accountAnchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 2}}>Welcome, Andrej.</Typography>
                    </Popover>
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}

Navbar.propTypes = {
    onOpenSidebar: PropTypes.func
};
