import {useTranslation} from "react-i18next";
import {useFormSchema} from "../use-form-schema.hook";
import {useForm} from "react-hook-form";

export const useResetValidation = () => {
    const {t} = useTranslation(['common']);

    const {provider, resolver, Controller} = useFormSchema();
    const schema = provider.object().shape({
        password: provider.string()
            .min(6, t('MINIMUM_LENGTH', {length: 6}))
            .required(t('REQUIRED_FIELD')),
    });

    const form = useForm({
        resolver: resolver(schema as any)
    });

    return {
        Controller,
        ...form
    }

}
