import {create, findByPk, findOne, getCollectionReference, update} from './base.service';

const _reference = () => {
    return getCollectionReference("users");
}

/**
 * Finds user by primary key
 * @param key Primary key
 */
export const findUserByPk = async (key: string) => {
    return findByPk(_reference(), key);
}

/**
 * Finds user by email
 * @param email Email
 */
export const findUserByEmail = async (email: string) => {
    return findOne(_reference(), [['email', '==', email]]);
}

/**
 * Creates user
 * @param payload
 */
export const createUser = async (payload: any) => {
    return create(_reference(), payload);
}

/**
 * Updates user data
 * @param key Primary key
 * @param payload
 */
export const updateUser = async (key: string, payload: any) => {
    return update(_reference(), key, payload);
}

/**
 * Creates or updates user
 * @param payload
 */
export const createOrUpdateUser = async (payload: any) => {
    const {email} = payload;
    try {
        const user = await findUserByEmail(email);
        return user ? updateUser(user.id, payload) : createUser(payload);
    } catch (err) {
        return createUser(payload);
    }
}
