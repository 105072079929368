export const buildQuery = (ref: any, params: any) => {
    params.forEach((p: any) => {
        const key = p[0];
        const op = p[1];
        const value = p[2];
        if(value) {
            ref = ref.where(key, op, value);
        }
    });
    return ref;
}
